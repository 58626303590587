<template>
  <div>
    <inputText
      :data="data.button"
      :label="$t('design.info.button.text')"
      :callback="
        function(value) {
          setValue(value);
        }
      "
      :error="false"
      :styling="{ input: 'width: 100%; display: block' }"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: this.getValue()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setValue(ret) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.info.button = ret;
      this.$store.commit("business/prefetch", data);
    },
    getValue() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      if (typeof data.info === "undefined") {
        return {
          button: this.$t("design.info.button.text")
        };
      }
      return data.info;
    }
  }
};
</script>
<style lang="sass" scoped></style>
